import React from 'react';
import './MyPortfolio.css'; // Ensure the CSS file is linked

const MyPortfolio = ({ openFolder }) => {
  return (
    <div className="my-portfolio-custom-content">
      <div className="section-custom">
        <h3 className="section-title-custom">Files Stored on This Computer</h3>
        <div className="folder-container-custom">
          <img src={`${process.env.PUBLIC_URL}/documents.png`} alt="Shared Documents" className="folder-icon-shared-docs-custom" />
          <div className="folder-label-shared-docs-custom">Shared Documents</div>
          
          <img src={`${process.env.PUBLIC_URL}/documents.png`} alt="Sarthak Bansal's Documents" className="folder-icon-sarthak-docs-custom" />
          <div className="folder-label-sarthak-docs-custom">Sarthak Bansal's Documents</div>
        </div>
      </div>

      <div className="section-custom">
        <h3 className="section-title-custom">Hard Disk Drives</h3>
        <div className="folder-container-custom">
          <img src={`${process.env.PUBLIC_URL}/1241.ico`} alt="Local Disk C" className="folder-icon-local-disk-custom" />
          <div className="folder-label-local-disk-custom">Local Disk (C:)</div>
        </div>
      </div>

      <div className="section-custom">
        <h3 className="section-title-custom">Devices with Removable Storage</h3>
        <div className="folder-container-custom">
          <img src={`${process.env.PUBLIC_URL}/46.ico`} alt="Floppy Disk A" className="folder-icon-floppy-disk-custom" />
          <div className="folder-label-floppy-disk-custom">3½ Floppy (A:)</div>
          
          <img src={`${process.env.PUBLIC_URL}/88.ico`} alt="CD Drive D" className="folder-icon-cd-drive-custom" />
          <div className="folder-label-cd-drive-custom">CD Drive (D:)</div>
        </div>
      </div>
    </div>
  );
};

export default MyPortfolio;
