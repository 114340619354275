import React, { useState } from 'react';
import './Project4.css'; // Make sure to link the CSS file

const Project4 = () => {
  // Array of images for the photo carousel
  const images = [
    `${process.env.PUBLIC_URL}/f1.png`
  ];

  // State to track the current image index in the carousel
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Function to move to the next image in the carousel
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Function to move to the previous image in the carousel
  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="project4-content">
      <h2 className="project4-title">Flappy Block Game</h2>

      <div className="project4-photo-gallery">
        <button className="carousel-button prev" onClick={prevImage}>{"<"}</button>
        <img
          src={images[currentImageIndex]}
          alt={`Flappy Block Screenshot ${currentImageIndex + 1}`}
          className="project4-carousel-image"
        />
        <button className="carousel-button next" onClick={nextImage}>{">"}</button>
      </div>

      <p className="project4-description">
        <strong>Flappy Block</strong> is an addictive arcade-style game inspired by the popular <strong>Flappy Bird</strong>, but with a unique twist — instead of a bird, players control a block navigating through obstacles. Built using <strong>HTML5</strong>, <strong>JavaScript</strong>, and the <strong>Phaser</strong> game framework, the game showcases my skills in game development and physics-based mechanics.
      </p>

      <div className="project4-links">
        <a href="https://github.com/SarthakSwaroop/flappy-block" target="_blank" rel="noopener noreferrer" className="project4-link">GitHub Repository</a>
        <a href="https://sarthakswaroop.github.io/flappy-block/" target="_blank" rel="noopener noreferrer" className="project4-link">Live Demo</a>
      </div>

      <h3 className="project4-section-title">Key Features</h3>
      <ul className="project4-feature-list">
        <li>Simple yet addictive gameplay, inspired by Flappy Bird.</li>
        <li>Smooth physics-based mechanics using the <strong>Phaser</strong> game framework.</li>
        <li>Custom-designed block and environment for a unique visual experience.</li>
        <li>Seamless performance across both desktop and mobile browsers.</li>
        <li>Developed using <strong>HTML5</strong>, <strong>JavaScript</strong>, and <strong>Phaser</strong> for cross-platform compatibility.</li>
      </ul>

      <h3 className="project4-section-title">My Roles</h3>
      <div className="project4-roles">
        <div className="project4-role-details">
          <p>Developed the entire game from scratch, including the game logic, player mechanics, and level design.</p>
          <p>Implemented smooth and responsive physics using the <strong>Phaser</strong> framework to create a seamless user experience.</p>
          <p>Designed custom game assets and optimized performance for cross-browser compatibility.</p>
        </div>
      </div>

      <h3 className="project4-section-title">Tech Stack</h3>
      <div className="project4-tech-stack">
        <div className="project4-tech-list">
          <div className="project4-tech-item">
            <img src={`${process.env.PUBLIC_URL}/skills/html-icon.png`} alt="HTML5" className="project4-tech-icon" />
            HTML5
          </div>
          <div className="project4-tech-item">
            <img src={`${process.env.PUBLIC_URL}/skills/javascript-programming-language-icon.png`} alt="JavaScript" className="project4-tech-icon" />
            JavaScript
          </div>
          <div className="project4-tech-item">
            <img src={`${process.env.PUBLIC_URL}/Gazebo.png`} alt="Phaser" className="project4-tech-icon" />
            Phaser
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project4;
