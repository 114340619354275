import React from 'react';
import './MyInfo.css'; // Make sure to import the CSS file


const MyInfo = () => (
  <div className="my-info-content">
  <img src={`${process.env.PUBLIC_URL}/52138161.jpg`} alt="Profile" className="my-info-picture" />

<div className="my-info-divider"></div>
    <p className="my-info-text">
    Hi! My name is Sarthak and I am a recent Computer Science graduate from University of Massachusetts-Amherst with a passion for technology and innovation. My academic journey has been marked by a deep dive into various programming languages and frameworks, and I've had the opportunity to work on exciting projects.

Driven by curiosity and a desire to solve real-world problems, I'm eager to bring my skills and enthusiasm to new challenges. Whether it's developing software solutions, analyzing data, or exploring emerging technologies, I'm ready to make a meaningful contribution and continue growing as a professional in the tech industry.
    </p>
    <div className="my-info-experiences">
      <h3 className="my-info-experiences-title">My Experiences</h3>
      <div className="sphere-container">
        <div className="sphere">
        <img src={`${process.env.PUBLIC_URL}/amazon-icon.png`} alt="Amazon Logo" className="ball-logo" />
     
        </div>
        <div className="sphere">
        <img src={`${process.env.PUBLIC_URL}/Massachusetts-Minutemen-Logo-2012.png`} alt="UMass Logo" className="ball-logo-2" />
        
        </div>
      </div>
      <div className="experience-texts">
        <div className="experience-text experience-1">
          <div className="info">
            <p className="position">SDE Intern</p>
            <p className="company">Amazon Inc.</p>
          </div>
        </div>
        <div className="experience-text experience-2">
          <div className="info">
            <p className="position">Tech Lead</p>
            <p className="company">Build Umass</p>

          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MyInfo;
