
  import React from 'react';
  import './SkillsContent.css';
  
  const skills = [
    { name: 'React', icon: `${process.env.PUBLIC_URL}/skills/react-js-icon.png` },
    { name: 'JavaScript', icon: `${process.env.PUBLIC_URL}/skills/javascript-programming-language-icon.png` },
    { name: 'Java', icon: `${process.env.PUBLIC_URL}/skills/java-programming-language-icon.png` },
    { name: 'Python', icon: `${process.env.PUBLIC_URL}/skills/python-programming-language-icon.png` },
    { name: 'C#', icon: `${process.env.PUBLIC_URL}/skills/c-sharp-programming-language-icon.png` },
    { name: 'Express', icon: `${process.env.PUBLIC_URL}/skills/Express.png` },
    { name: 'Node.js', icon: `${process.env.PUBLIC_URL}/skills/node-js-icon.png` },
    { name: 'C++', icon: `${process.env.PUBLIC_URL}/skills/c-plus-plus-programming-language-icon.png` },
    { name: 'HTML5', icon: `${process.env.PUBLIC_URL}/skills/html-icon.png` },
    { name: 'CSS3', icon: `${process.env.PUBLIC_URL}/skills/css-icon.png` },
    { name: 'AWS', icon: `${process.env.PUBLIC_URL}/skills/aws-icon.png` },
    { name: 'TypeScript', icon: `${process.env.PUBLIC_URL}/skills/typescript-programming-language-icon.png` },
    { name: 'Unity', icon: `${process.env.PUBLIC_URL}/skills/unity-game-engine-icon.png` },
    { name: 'TensorFlow', icon: `${process.env.PUBLIC_URL}/skills/google-tensorflow-icon.png` },
    { name: 'PyTorch', icon: `${process.env.PUBLIC_URL}/skills/PyTorch.png` },
    { name: 'scikit-learn', icon: `${process.env.PUBLIC_URL}/skills/scikit-learn.png` },
    { name: 'HuggingFace', icon: `${process.env.PUBLIC_URL}/skills/hf-logo.png` },
    { name: 'Next.js', icon: `${process.env.PUBLIC_URL}/skills/nextjs-icon.png` },
    { name: 'Figma', icon: `${process.env.PUBLIC_URL}/skills/figma-icon.png` },
    { name: 'WebPack', icon: `${process.env.PUBLIC_URL}/skills/webpack-icon.png` },
    { name: 'Vue.js', icon: `${process.env.PUBLIC_URL}/skills/vue-js-icon.png` },
    { name: 'Redux', icon: `${process.env.PUBLIC_URL}/skills/redux-icon.png` },
    { name: 'Docker', icon: `${process.env.PUBLIC_URL}/skills/docker-icon.png` },
    { name: 'PostgresSQL', icon: `${process.env.PUBLIC_URL}/skills/PostgresSQL.png` },
    { name: 'Material-UI', icon: `${process.env.PUBLIC_URL}/skills/Material-UI.png` },
    { name: 'MongoDB', icon: `${process.env.PUBLIC_URL}/skills/mongodb-icon.png` },
    { name: 'Git', icon: `${process.env.PUBLIC_URL}/skills/git-icon.png` },
    { name: 'MySQL', icon: `${process.env.PUBLIC_URL}/skills/mysql-icon.png` }
    // Add more skills here
  ];
  
  const SkillsContent = () => {
    return (
      <div className="skills-card">
        <h2 className="skills-title">My Skills</h2>
        <hr className="skills-divider" />
        <div className="skills-list">
          {skills.map((skill, index) => (
            <div className={`skill-item skill-item-${skill.name.toLowerCase()}`} key={index}>
            <img src={skill.icon} alt={`${skill.name} icon`} className="skill-icon" />
            {skill.name}
        </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default SkillsContent;
  

