
import React, { useState } from 'react';
import './App.css';
import Taskbar from './components/Taskbar';
import Wallpaper from './components/Wallpaper';
import DesktopIcon from './components/DesktopIcon';
import LoginPage from './components/LoginPage';
import FolderWindow from './components/FolderWindow';
import SkillsContent from './components/SkillsContent';
import MyInfo from './components/MyInfo';
import MyDocuments from './components/myDocuments'; // Ensure correct path
import MyProjects from './components/MyProjects';
import MyPortfolio from './components/MyPortfolio';



function App() {
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);
  const [minimizedFolders, setMinimizedFolders] = useState([]); 
  const [openFolders, setOpenFolders] = useState([]);
  const [highestZIndex, setHighestZIndex] = useState(1);
  const [activeProject, setActiveProject] = useState(null);

  const handleLogin = () => {
    setShowWelcomeScreen(true);
    setTimeout(() => {
      setShowWelcomeScreen(false);
      setIsLoggedIn(true);
    }, 1500); // Simulate welcome screen for 1.5 seconds
  };
  const handleLogOff = () => {
    setIsLoggedIn(false); // Logs off the user and returns to the login screen
  };
  const openFolder = (folderName, folderImage) => {
    if (!openFolders.some(folder => folder.name === folderName)) {
      setOpenFolders([...openFolders, { name: folderName, zIndex: highestZIndex, image: folderImage || `${process.env.PUBLIC_URL}/documents.png` }]);
      setHighestZIndex(prevZIndex => prevZIndex + 1);
    } else {
      bringToFront(folderName);
    }
    setMinimizedFolders(minimizedFolders.filter(folder => folder.name !== folderName));
  };

  const bringToFront = (folderName) => {
    setOpenFolders(openFolders.map(folder => 
      folder.name === folderName ? { ...folder, zIndex: highestZIndex } : folder
    ));
    setHighestZIndex(prevZIndex => prevZIndex + 1); // Increment zIndex when bringing to front
  };

  const closeFolder = (folderName) => {
    // Close the folder by removing it from the array
    setOpenFolders(openFolders.filter(folder => folder.name !== folderName));
  };

  const minimizeFolder = (folderName) => {
    setMinimizedFolders([...minimizedFolders, { name: folderName, image: `${process.env.PUBLIC_URL}/documents.png` }]);
    setOpenFolders(openFolders.filter(folder => folder.name !== folderName));
  };

  const handleBack = () => {
    if (activeProject) {
      setActiveProject(null); // Go back to project list in MyProjects
    }
  };

  return (
    <div className="App">
      {isLoggedIn ? (
        <>
          <Wallpaper />
          <div className="desktop">
            <DesktopIcon
              icon={`${process.env.PUBLIC_URL}/88.ico`}
              label="Info"
              id="my-info-icon"
              onClick={() => openFolder('Info')}
            />
            <DesktopIcon
              icon={`${process.env.PUBLIC_URL}/xpsp2res.dll_14_10702_1025-4.png`}
              label="Portfolio"
              id="sarthak-portfolio-icon"
              onClick={() => openFolder('Portfolio')}
            />
            <DesktopIcon
              icon={`${process.env.PUBLIC_URL}/295.ico`}
              label="Skills"
              id="sarthak-skill-icon"
              onClick={() => openFolder('Skills')}
            />
            <DesktopIcon
              icon={`${process.env.PUBLIC_URL}/documents.png`}
              label="Projects"
              id="sarthak-project-icon"
              onClick={() => openFolder('Projects')}
            />
            <DesktopIcon
              icon={`${process.env.PUBLIC_URL}/documents.png`}
              label="Documents"
              id="my-documents-icon"
              onClick={() => openFolder('Documents')}
            />
          </div>
          
          <Taskbar
            minimizedFolders={minimizedFolders}
            onRestore={openFolder}
            onLogOff={handleLogOff}
            openFolder={openFolder}  // Added openFolder prop for the right-side buttons
          />

          {openFolders.map((folder, index) => (
            <FolderWindow
              key={index}
              folderName={folder.name}
              onClose={() => closeFolder(folder.name)}
              onMinimize={() => minimizeFolder(folder.name)}
              onClick={() => bringToFront(folder.name)}
              zIndex={folder.zIndex}
              onBack={handleBack} // Pass handleBack to FolderWindow
              canGoBack={activeProject !== null} // Enable back button if inside a project
            >
              {folder.name === 'Skills' ? (
                <SkillsContent />
              ) : folder.name === 'Info' ? (
                <MyInfo />
              ) : folder.name === 'Documents' ? (
                <MyDocuments />
              ) : folder.name === 'Portfolio' ? (
                <MyPortfolio />
              ) : folder.name === 'Projects' ? (
                <MyProjects activeProject={activeProject} setActiveProject={setActiveProject} />
              ) : (
                <p>This is the content of {folder.name} folder.</p>
              )}
            </FolderWindow>
          ))}
        </>
      ) : showWelcomeScreen ? (
        <div className="welcome-screen">
          <div className="top-background"></div> {/* Top darker background */}
          <div className="bottom-background"></div> {/* Bottom darker background */}
          <div className="top-line"></div> {/* Top white line */}
          <div className="center-line"></div>

          <h1 className="welcome-text">Welcome</h1>

          <div className="user-section no-hover"> {/* 'no-hover' class disables hover effects */}
            <div className="user-icon" id="guest">
              <img src={`${process.env.PUBLIC_URL}/wcm0jmmpxkbc1.jpeg`} alt="Guest Profile" />
              <span className="user-name">Sarthak's Portfolio</span>
            </div>
          </div>

          <div className="turn-off-container">
            <img src={`${process.env.PUBLIC_URL}/Dtafalonso-Modern-Xp-ModernXP-02-System-Power.ico`} alt="Turn Off Icon" />
            <span>Turn off computer</span>
          </div>
          <div className="bottom-line"></div> {/* Bottom white line */}
        </div>
      ) : (
        <LoginPage onLogin={handleLogin} />
      )}
    </div>
  );
}

export default App;
