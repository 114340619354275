import React, { useState } from 'react';
import './Project2.css'; // Make sure to link the CSS file

const Project2 = () => {
  // Array of images for the photo carousel
  const images = [
    `${process.env.PUBLIC_URL}/a1.png`,
  `${process.env.PUBLIC_URL}/a2.png`,
  `${process.env.PUBLIC_URL}/a3.png`
    ];

  // State to track the current image index in the carousel
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Function to move to the next image in the carousel
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Function to move to the previous image in the carousel
  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="project2-content">
      <h2 className="project2-title">Unity Exploration Game</h2>
      
      <div className="project2-photo-gallery">
        <button className="carousel-button prev" onClick={prevImage}>{"<"}</button>
        <img
          src={images[currentImageIndex]}
          alt={`Unity Game Screenshot ${currentImageIndex + 1}`}
          className="project2-carousel-image"
        />
        <button className="carousel-button next" onClick={nextImage}>{">"}</button>
      </div>

      <p className="project2-description">
        This Unity-based exploration game features an open-ended storyline, immersive scenery, and interactive NPCs. Players are encouraged to explore a vast world filled with detailed environments, complex physics, and rich storytelling. The game provides a seamless and captivating experience as players engage with various game elements including dynamic NPC interactions and realistic physics simulations.
      </p>

      <div className="project2-links">
        <a href="https://drive.google.com/file/d/1rKt1Q3ydIhjnPVzmX8mG49l_UH5UNUkb/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="project2-link">Code Repository</a>
        <a href="https://drive.google.com/file/d/11tl3JVW6uoNKmcqU38teem16EA7Y3HOz/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="project2-link">Demo Video</a>
      </div>

      <h3 className="project2-section-title">Key Features</h3>
      <ul className="project2-feature-list">
        <li>Open-ended exploration with a dynamic storyline.</li>
        <li>Engaging NPC interactions with branching dialogues.</li>
        <li>Realistic physics and complex environmental systems.</li>
        <li>Immersive scenery and diverse landscapes.</li>
        <li>Custom-built gameplay mechanics within Unity.</li>
      </ul>

      <h3 className="project2-section-title">My Roles</h3>
      <div className="project2-roles">
        <div className="project2-role-details">
          <p>Designed and developed the entire game, including NPC dialogues, player mechanics, and environment physics.</p>
          <p>Implemented complex NPC interactions, world-building, and scenic design using Unity and C#.</p>
        </div>
      </div>

      <h3 className="project2-section-title">Tech Stack</h3>
      <div className="project2-tech-stack">
        <div className="project2-tech-list">
          <div className="project2-tech-item">
            <img src={`${process.env.PUBLIC_URL}/skills/unity-game-engine-icon.png`} alt="Unity" className="project2-tech-icon" />
            Unity
          </div>
          <div className="project2-tech-item">
            <img src={`${process.env.PUBLIC_URL}/skills/c-sharp-programming-language-icon.png`} alt="C#" className="project2-tech-icon" />
            C#
          </div>
          <div className="project2-tech-item">
            <img src={`${process.env.PUBLIC_URL}/Blender.png`} alt="Blender" className="project2-tech-icon" />
            Blender
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project2;
