import React from 'react';
import Project1 from './Project1';
import Project2 from './Project2';
import Project3 from './Project3';
import Project4 from './Project4';
import Project5 from './Project5';
import Project6 from './Project6';
import './MyProjects.css';  // Ensure CSS is imported

const MyProjects = ({ activeProject, setActiveProject }) => {
  const renderProjectContent = () => {
    switch (activeProject) {
      case 'Project 1':
        return <Project1 />;
      case 'Project 2':
        return <Project2 />;
      case 'Project 3':
        return <Project3 />;
      case 'Project 4':
        return <Project4 />;
      case 'Project 5':
        return <Project5 />;
      case 'Project 6':
        return <Project6 />;
      default:
        return renderProjectList();  // Render the list of project folders if no project is active
    }
  };

  const renderProjectList = () => {
    return (
      <div className="folder-container">
        <div className="folder project-1" onClick={() => setActiveProject('Project 1')}>
          <img src={process.env.PUBLIC_URL + '/documents.png'} alt="Project 1 Icon" className="folder-icon" />
          <div className="folder-label">Office Hours Scheduler</div>
        </div>
        <div className="folder project-2" onClick={() => setActiveProject('Project 2')}>
          <img src={process.env.PUBLIC_URL + '/documents.png'} alt="Project 2 Icon" className="folder-icon" />
          <div className="folder-label">Unity Exploration Game</div>
        </div>
        <div className="folder project-3" onClick={() => setActiveProject('Project 3')}>
          <img src={process.env.PUBLIC_URL + '/documents.png'} alt="Project 3 Icon" className="folder-icon" />
          <div className="folder-label">DataPicks - MLB Analytics Platform</div>
        </div>
        <div className="folder project-4" onClick={() => setActiveProject('Project 4')}>
          <img src={process.env.PUBLIC_URL + '/documents.png'} alt="Project 4 Icon" className="folder-icon" />
          <div className="folder-label">Flappy Block Game</div>
        </div>
        <div className="folder project-5" onClick={() => setActiveProject('Project 5')}>
          <img src={process.env.PUBLIC_URL + '/documents.png'} alt="Project 5 Icon" className="folder-icon" />
          <div className="folder-label">Algorithm Visualizer</div>
        </div>
        <div className="folder project-6" onClick={() => setActiveProject('Project 6')}>
          <img src={process.env.PUBLIC_URL + '/documents.png'} alt="Project 6 Icon" className="folder-icon" />
          <div className="folder-label">Windows XP Personal Website</div>
        </div>
      </div>
    );
  };

  return (
    <div className="my-projects-container">
      {renderProjectContent()} {/* Render either the project content or the list */}
    </div>
  );
};

export default MyProjects;
