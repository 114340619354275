import React, { useState } from 'react';
import './Project5.css'; // Link the CSS file

const Project5 = () => {
  // Array of images for the photo carousel
  const images = [
    `${process.env.PUBLIC_URL}/v1.png`,
    `${process.env.PUBLIC_URL}/v2.png`
  ];

  // State to track the current image index in the carousel
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Function to move to the next image in the carousel
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Function to move to the previous image in the carousel
  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="project5-content">
      <h2 className="project5-title">Algorithm Visualizer</h2>

      <div className="project5-photo-gallery">
        <button className="carousel-button prev" onClick={prevImage}>{"<"}</button>
        <img
          src={images[currentImageIndex]}
          alt={`Algorithm Visualizer Screenshot ${currentImageIndex + 1}`}
          className="project5-carousel-image"
        />
        <button className="carousel-button next" onClick={nextImage}>{">"}</button>
      </div>

      <p className="project5-description">
        <strong>Algorithm Visualizer</strong> is an interactive tool designed to demonstrate and compare the effectiveness of various pathfinding algorithms. This project visually showcases how algorithms like <strong>Dijkstra’s</strong> and <strong>A*</strong> navigate through a grid to find the shortest path to a target. Developed as one of my first personal projects, this visualizer is both an educational tool and a proof of concept, illustrating my deep understanding of algorithmic problem-solving and data structures.
      </p>

      <div className="project5-links">
        <a href="https://github.com/SarthakSwaroop/algorithm-app" target="_blank" rel="noopener noreferrer" className="project5-link">GitHub Repository</a>
        <a href="https://sarthakswaroop.github.io/algorithm-app/" target="_blank" rel="noopener noreferrer" className="project5-link">Live Demo</a>
      </div>

      <h3 className="project5-section-title">Key Features</h3>
      <ul className="project5-feature-list">
        <li>Interactive visualization of popular pathfinding algorithms, including <strong>Dijkstra’s</strong> and <strong>A*</strong>.</li>
        <li>Real-time feedback on algorithm performance and pathfinding efficiency.</li>
        <li>Customizable grid layout with adjustable start and end points.</li>
        <li>Visual representation of obstacles and their impact on the pathfinding process.</li>
        <li>Developed using <strong>JavaScript</strong>, <strong>HTML5</strong>, and <strong>CSS3</strong> for dynamic and responsive user interactions.</li>
      </ul>

      <h3 className="project5-section-title">My Roles</h3>
      <div className="project5-roles">
        <div className="project5-role-details">
          <p>Designed and implemented the entire visualizer, focusing on clear and intuitive user interactions.</p>
          <p>Developed efficient and optimized versions of pathfinding algorithms such as <strong>Dijkstra’s</strong> and <strong>A*</strong> for real-time visualization.</p>
          <p>Handled the front-end development using <strong>JavaScript</strong> and <strong>HTML5</strong>, ensuring smooth and responsive animations across devices.</p>
        </div>
      </div>

      <h3 className="project5-section-title">Tech Stack</h3>
      <div className="project5-tech-stack">
        <div className="project5-tech-list">
          <div className="project5-tech-item">
            <img src={`${process.env.PUBLIC_URL}/skills/javascript-programming-language-icon.png`} alt="JavaScript" className="project5-tech-icon" />
            JavaScript
          </div>
          <div className="project5-tech-item">
            <img src={`${process.env.PUBLIC_URL}/skills/html-icon.png`} alt="HTML5" className="project5-tech-icon" />
            HTML5
          </div>
          <div className="project5-tech-item">
            <img src={`${process.env.PUBLIC_URL}/skills/css-icon.png`} alt="CSS3" className="project5-tech-icon" />
            CSS3
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project5;
