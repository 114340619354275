import React from 'react';
import './Wallpaper.css'; // We'll style it in a CSS file

const Wallpaper = () => {
  return (
    <div className="wallpaper">
      {/* The wallpaper will be set via CSS */}
    </div>
  );
};

export default Wallpaper;
