import React, { useState } from 'react';
import './Project1.css'; // Ensure the CSS is imported

const images = [
  `${process.env.PUBLIC_URL}/project1-1.png`,
  `${process.env.PUBLIC_URL}/project1-2.png`,
  `${process.env.PUBLIC_URL}/project1-3.png`,
  `${process.env.PUBLIC_URL}/project1-4.png`
];

const techStack = [
  { name: 'React', icon: `${process.env.PUBLIC_URL}/skills/react-js-icon.png` },
  { name: 'Node.js', icon: `${process.env.PUBLIC_URL}/skills/node-js-icon.png` },
  { name: 'Express', icon: `${process.env.PUBLIC_URL}/skills/Express.png` },
  { name: 'MongoDB', icon: `${process.env.PUBLIC_URL}/skills/mongodb-icon.png` },
];

const Project1 = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="project1-content">
      {/* Project Name */}
      <h2 className="project1-title">Office Hours Scheduler</h2>
           
      {/* Carousel for Project Images */}
      <div className="project1-carousel">
        <button className="carousel-button prev" onClick={prevImage}>❮</button>
        <img src={images[currentImageIndex]} alt="Project screenshot" className="project1-carousel-image" />
        <button className="carousel-button next" onClick={nextImage}>❯</button>
      </div>

      {/* Project Description */}
      <p className="project1-description">
        The Office Hours Scheduler is a modern web application designed to simplify the process of scheduling appointments between students and professors. Built using React.js and Node.js, it integrates with the Google Calendar API to enable real-time appointment booking and management. The app provides a clean, responsive interface for users, ensuring a seamless experience across devices, while handling all backend processes securely with JWT authentication and RESTful APIs.
      </p>

      {/* Project Links */}
      <div className="project1-links">
        <a href="https://github.com/SarthakSwaroop/Office-Hours-320" target="_blank" rel="noopener noreferrer" className="project1-link">
          GitHub Repository
        </a>
        <a href="https://github.com/SarthakSwaroop/Office-Hours-320" target="_blank" rel="noopener noreferrer" className="project1-link">
          Live Demo
        </a>
      </div>

      {/* Key Features */}
      <div className="project1-features">
        <h3 className="project1-section-title">Key Features</h3>
        <ul className="project1-feature-list">
          <li>Automated Scheduling with real-time Google Calendar integration.</li>
          <li>Responsive UI built with React.js, ensuring cross-device compatibility.</li>
          <li>Scalable Backend using Node.js and Express.js for smooth performance.</li>
          <li>Email Notifications for booking confirmations and updates.</li>
          <li>Secure Authentication using JWT for data protection.</li>
          <li>RESTful API design for efficient data communication.</li>
        </ul>
      </div>

      {/* My Roles */}
      <div className="project1-roles">
        <h3 className="project1-section-title">My Role</h3>
        <div className="project1-role-details">
          <p><strong>Frontend Developer:</strong> Built the user interface with React.js, ensuring responsive design.</p>
          <p><strong>Backend Developer:</strong> Developed REST APIs using Node.js and Express.js for managing appointments and users.</p>
          <p><strong>API Integration:</strong> Integrated the Google Calendar API to handle scheduling automation.</p>
        </div>
      </div>

      {/* Tech Stack */}
      <div className="project1-tech-stack">
        <h3 className="project1-section-title">Tech Stack</h3>
        <div className="project1-tech-list">
          {techStack.map((tech, index) => (
            <div className={`project1-tech-item project1-tech-item-${tech.name.toLowerCase()}`} key={index}>
              <img src={tech.icon} alt={`${tech.name} icon`} className="project1-tech-icon" />
              {tech.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Project1;
