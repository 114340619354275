import React from 'react';
import './LoginPage.css';

const LoginPage = ({ onLogin }) => {
  return (
    <div className="login-container">
      <div className="top-background"></div> {/* Top darker background */}
      <div className="bottom-background"></div> {/* Bottom darker background */}

      <div className="top-line"></div> {/* Top white line */}
      <div className="bottom-line"></div> {/* Bottom white line */}

      <div className="logo-container">
        <img src={`${process.env.PUBLIC_URL}/windows-xp-logo-white-text-transparent-bg.png`} alt="Windows XP Logo" />
        <div className="welcome-text">To begin, click your user name</div>
      </div>

      <div className="center-line"></div> {/* Vertical white line */}

      <div className="user-section">
        <div className="user-icon" id="guest" onClick={onLogin}> {/* Add onClick handler here */}
          <img src={`${process.env.PUBLIC_URL}/wcm0jmmpxkbc1.jpeg`} alt="Guest Profile" />
          <span className="user-name">Sarthak's Portfolio</span>
        </div>
      </div>

      <div className="turn-off-container">
        <img src={`${process.env.PUBLIC_URL}/Dtafalonso-Modern-Xp-ModernXP-02-System-Power.ico`} alt="Turn Off Icon" />
        <span>Turn off computer</span>
      </div>
    </div>
  );
};

export default LoginPage;
