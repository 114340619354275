import React from 'react';

const Project1 = () => {
  return (
    <div>
      <h2>DataPicks - MLB Analytics Platform</h2>
      <p>More information coming soon ....</p>
    </div>
  );
};

export default Project1;