import React, { useState } from 'react';
import './Project6.css'; // Link the CSS file

const Project6 = () => {
  // Array of images for the photo carousel
  const images = [
    `${process.env.PUBLIC_URL}/xp1.png`,
    `${process.env.PUBLIC_URL}/xp2.png`,
    `${process.env.PUBLIC_URL}/xp3.png`,
    `${process.env.PUBLIC_URL}/xp4.png`
  ];

  // State to track the current image index in the carousel
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Function to move to the next image in the carousel
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Function to move to the previous image in the carousel
  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="project6-content">
      <h2 className="project6-title">Windows XP Style Portfolio Website</h2>

      <div className="project6-photo-gallery">
        <button className="carousel-button prev" onClick={prevImage}>{"<"}</button>
        <img
          src={images[currentImageIndex]}
          alt={`Windows XP Portfolio Screenshot ${currentImageIndex + 1}`}
          className="project6-carousel-image"
        />
        <button className="carousel-button next" onClick={nextImage}>{">"}</button>
      </div>

      <p className="project6-description">
        This portfolio project takes inspiration from the classic Windows XP operating system, blending nostalgia with modern web development techniques. Built using React.js, this interactive website simulates the iconic Windows XP interface with functional desktop icons, taskbars, draggable windows, and more. The goal was to create a memorable, fun, and professional portfolio that showcases both creativity and technical skills in front-end development. This project reflects my dedication to user experience design, while also highlighting my mastery of modern JavaScript frameworks like React.js and CSS3 for intricate styling.
      </p>

      <div className="project6-links">
        <a href="https://github.com/SarthakSwaroop/my-portfolio-xp" target="_blank" rel="noopener noreferrer" className="project6-link">GitHub Repository</a>
        <a href="https://sarthakswaroop.github.io/my-portfolio-xp/" target="_blank" rel="noopener noreferrer" className="project6-link">Live Demo</a>
      </div>

      <h3 className="project6-section-title">Key Features</h3>
      <ul className="project6-feature-list">
        <li>Fully interactive Windows XP-style UI with desktop icons, taskbars, and draggable windows.</li>
        <li>Built using React.js for smooth and responsive performance.</li>
        <li>Custom design that mimics the look and feel of the classic Windows XP interface.</li>
        <li>Modular architecture allowing easy addition of new features and projects.</li>
      </ul>

      <h3 className="project6-section-title">My Roles</h3>
      <div className="project6-roles">
        <div className="project6-role-details">
          <p>Designed and implemented the entire Windows XP style UI using React.js and CSS.</p>
          <p>Developed modular components like taskbars, draggable windows, and desktop icons to simulate the desktop experience.</p>
          <p>Ensured responsive design and cross-browser compatibility across all major browsers.</p>
        </div>
      </div>

      <h3 className="project6-section-title">Tech Stack</h3>
      <div className="project6-tech-stack">
        <div className="project6-tech-list">
          <div className="project6-tech-item">
            <img src={`${process.env.PUBLIC_URL}/skills/react-js-icon.png`} alt="React" className="project6-tech-icon" />
            React.js
          </div>
          <div className="project6-tech-item">
            <img src={`${process.env.PUBLIC_URL}/skills/css-icon.png`} alt="CSS3" className="project6-tech-icon" />
            CSS3
          </div>
          <div className="project6-tech-item">
            <img src={`${process.env.PUBLIC_URL}/skills/javascript-programming-language-icon.png`} alt="JavaScript" className="project6-tech-icon" />
            JavaScript
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project6;
