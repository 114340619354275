import React from 'react';
import Draggable from 'react-draggable';
import './FolderWindow.css';

const FolderWindow = ({ folderName, onClose, onMinimize, onClick, zIndex, children, onBack, canGoBack }) => {
  return (
    <Draggable>
      <div
        className="folder-window"
        onClick={onClick} // Brings to the front when clicked
        style={{ zIndex: zIndex }} // Dynamic zIndex
      >
        <div className="folder-window-header">
          <span>{folderName}</span>
          <button onClick={(e) => { e.stopPropagation(); onMinimize(); }} className="minimize-button">
            _
          </button>
          <button onClick={(e) => { e.stopPropagation(); onClose(); }} className="close-button">
            X
          </button>
        </div>
        <div className="folder-window-toolbar">
          <div className="toolbar-buttons">
            <button>File</button>
            <button>Edit</button>
            <button>View</button>
            <button>Favorites</button>
            <button>Tools</button>
            <button>Help</button>
          </div>
          <div className="toolbar-logo">
            <img src={`${process.env.PUBLIC_URL}/windows.png`} alt="Windows Logo" />
          </div>
        </div>
        <div className="folder-window-navigation">
          <div className="navigation-buttons">
            <button
              className={`back-button ${canGoBack ? 'enabled' : 'disabled'}`}
              onClick={canGoBack ? onBack : null} // Only trigger if canGoBack is true
              disabled={!canGoBack}
            >
              <img src={`${process.env.PUBLIC_URL}/xp-arrow-icon-previous.png`} alt="Back" />
              <span>Back</span>
            </button>
            <button className="forward-button">
              <img src={`${process.env.PUBLIC_URL}/xp-arrow-icon-next.png`} alt="Forward" />
            </button>
          </div>
        </div>
        <div className="folder-window-content">
          {children}
        </div>
      </div>
    </Draggable>
  );
};

export default FolderWindow;
