import React from 'react';
import './myDocuments.css'; // Make sure to import the CSS file

const MyDocuments = () => {
  const openResume = () => {
    window.open(`${process.env.PUBLIC_URL}/Sarthak-Swaroop-Bansal-Resume.pdf`, '_blank');
  };

  return (
    <div className="folder-container">
      <div className="folder" onClick={openResume}>
        <img
          src={`${process.env.PUBLIC_URL}/12.ico`}
          alt="Folder Icon"
          className="folder-icon"
        />
        <div className="folder-label">My Resume</div>
      </div>
    </div>
  );
};

export default MyDocuments;
