import React from 'react';
import './DesktopIcon.css';

const DesktopIcon = ({ icon, label, id, onClick }) => {
  return (
    <div className="desktop-icon" id={id} onClick={onClick}>
      <img src={icon} alt={label} />
      <span>{label}</span>
    </div>
  );
};

export default DesktopIcon;
