import React, { useState, useEffect } from 'react';
import './Taskbar.css';

const Taskbar = ({ minimizedFolders, onRestore }) => {
  const [time, setTime] = useState(new Date());
  const [isStartMenuOpen, setIsStartMenuOpen] = useState(false);

  // Update the time every second
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const toggleStartMenu = () => {
    setIsStartMenuOpen(!isStartMenuOpen);
  };

  useEffect(() => {
    const closeMenu = (e) => {
      if (!e.target.closest('.start-button') && !e.target.closest('.start-menu')) {
        setIsStartMenuOpen(false);
      }
    };
    document.addEventListener('click', closeMenu);
    return () => document.removeEventListener('click', closeMenu);
  }, []);

  return (
    <div className="taskbar">
      <div className="start-button" onClick={toggleStartMenu}>
        <img src={`${process.env.PUBLIC_URL}/windows.png`} alt="Start" />
        <span>Start</span>
      </div>

      {/* {minimizedFolders.map((folderName, index) => (
        <div key={index} className="taskbar-item" onClick={() => onRestore(folderName)}>
          {folderName}
        </div>
      ))} */}
      {minimizedFolders.map((folder, index) => (
      <div key={index} className="taskbar-item" onClick={() => onRestore(folder.name)}>
        <img src={`${process.env.PUBLIC_URL}/documents.png`} alt={`${folder.name} Icon`} className="taskbar-item-icon" />
        <span>{folder.name}</span>
      </div>
    ))}
      
      {isStartMenuOpen && (
        <div className="start-menu">
          <div className="start-menu-header">
            <img className="profile-pic" src={`${process.env.PUBLIC_URL}/wcm0jmmpxkbc1.jpeg`} alt="Profile" />
            <span className="profile-name">Sarthak's Portfolio</span>
          </div>
          <div className="start-menu-content">
            <div className="start-menu-content-left">
            <div className="start-menu-item" onClick={() => window.open('https://github.com/SarthakSwaroop', '_blank')}>
                <img src={`${process.env.PUBLIC_URL}/mshtml.dll_14_2660-2.png`} alt="Internet Icon" />
                <div className="menu-item-text">
                  <strong>Internet</strong>
                  <span>Sarthak's Github</span>
                </div>
              </div>
              <div className="start-menu-item" onClick={() => window.open('https://www.linkedin.com/in/ssb00/', '_blank')}>
                <img src={`${process.env.PUBLIC_URL}/sprb0401.dll_14_2013_1025.png`} alt="Connect Icon" />
                <div className="menu-item-text">
                  <strong>Connect</strong>
                  <span>Connect on LinkedIn!</span>
                </div>
              </div>
              <div className="start-menu-item" onClick={() => window.location.href = 'mailto:sarthakwbansal@gmail.com'}>
                <img src={`${process.env.PUBLIC_URL}/msimn.exe_14_2-3.png`} alt="Email Icon" />
                <div className="menu-item-text">
                  <strong>E-mail</strong>
                  <span>Send an e-mail</span>
                </div>
              </div>
            <div className="start-menu-item">
              <img src={`${process.env.PUBLIC_URL}/paint.png`} alt="Paint Icon" />
              <div className="menu-item-text">
                <strong>Paint</strong>
                <span>Graphics editor</span>
              </div>
            </div>
            <div className="start-menu-item">
              <img src={`${process.env.PUBLIC_URL}/winmine.exe_14_100-2.png`} alt="Minesweeper Icon" />
              <div className="menu-item-text">
                <strong>Minesweeper</strong>
                <span>Classic game</span>
              </div>
            </div>
            <div className="start-menu-item">
              <img src={`${process.env.PUBLIC_URL}/trash.png`} alt="Recycle Bin Icon" />
              <div className="menu-item-text">
                <strong>Recycle Bin</strong>
                <span>Trash folder</span>
              </div>
            </div>

          <div className="all-programs-separator"></div>
    <div className="all-programs-button">
    <span>All Programs</span>
      <img src={`${process.env.PUBLIC_URL}/shell32.dll_14_246-1.png`} alt="All Programs Icon" />
    </div>
            </div>
            <div className="vertical-separator"></div>
            <div className="start-menu-content-right">
              <div className="start-menu-item-right">
                <img src={`${process.env.PUBLIC_URL}/computer.png`} alt="Documents Icon" />
                <div className="menu-item-text-right">
                  <strong>My Portfolio</strong>
                </div>
              </div>
              <div className="start-menu-item-right"> 
                <img src={`${process.env.PUBLIC_URL}/documents.png`} alt="/xpsp2res.dll_14_10702_1025-4.png Icon" />
                <div className="menu-item-text-right">
                  <strong>My Projects</strong>
                </div>
              </div>
              <div className="start-menu-item-right">
                <img src={`${process.env.PUBLIC_URL}/295.ico`} alt="Projects Icon" />
                <div className="menu-item-text-right">
                  <strong>My Skills</strong>
                </div>
              </div>
              <div className="start-menu-item-right">
                <img src={`${process.env.PUBLIC_URL}/88.ico`} alt="Documents Icon" />
                <div className="menu-item-text-right">
                  <strong>My Info</strong>
                </div>
              </div>
              <div className="start-menu-item-right">
                <img src={`${process.env.PUBLIC_URL}/srchui.dll_14_235-3.png`} alt="Documents Icon" />
                <div className="menu-item-text-right">
                  <strong>My Documents</strong>
                </div>
              </div>
              
              <div className="right-column-separator"></div>
              <div className="start-menu-item-right">
    <img src={`${process.env.PUBLIC_URL}/hnetwiz.dll_14_10001-8.png`} alt="Help Icon" />
    <div className="menu-item-text-right-nonbold">
      Help and Support
    </div>
  </div>
  <div className="start-menu-item-right">
    <img src={`${process.env.PUBLIC_URL}/xpsp2res.dll_14_2023_1025-2.png`} alt="Search Icon" />
    <div className="menu-item-text-right-nonbold">
      Search
    </div>
  </div>
  <div className="start-menu-item-right">
    <img src={`${process.env.PUBLIC_URL}/shell32.dll_14_160-3.png`} alt="Run Icon" />
    <div className="menu-item-text-right-nonbold">
      Run...
    </div>
  </div>

            </div>
          </div>


          {/* Footer with Log Off and Turn Off Computer */}
          <div className="start-menu-footer">
            <div className="footer-item">
              <img src={`${process.env.PUBLIC_URL}/log-off.png`} alt="Log Off Icon" />
              <span>Log Off</span>
            </div>
          </div>
        </div>
      )}

      <div className="clock-container">
        {time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
      </div>
    </div>
  );
};

export default Taskbar;
